<template>
  <div class="premium-container main-cnt">
    <div class="title">规则列表</div>
    <div class="content">
      <common-table
        ref="tableRef"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :filters="filters"
        :ispaging="true"
        :apiName="OrderApi.refundList"
        :columns="tableColumns"
        @edit="openDialog"
        @statusChange="statusChange"
      >
        <!-- <template #operate>
          <el-button type="primary" round @click="openDialog">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增规则</el-button
          >
        </template> -->
      </common-table>
    </div>
    <w-dialog
      ref="dialogRef"
      class="vip-dialog"
      :title="ruleForm.m_id ? '编辑会员' : '新增会员'"
      width="50%"
      btnWidth="140px"
      top="20vh"
      :confirmText="ruleForm.m_id ? '确认编辑' : '确认新增'"
      @wConfirm="handleSure"
    >
      <el-form
        class="add-form"
        ref="formRef"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="会员姓名" prop="m_realname">
              <el-input
                v-model="ruleForm.m_realname"
                clearable
                placeholder="请输入会员姓名"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="会员性别" prop="m_sex">
              <el-select
                v-model="ruleForm.m_sex"
                placeholder="请选择会员性别"
                clearable
              >
                <el-option label="未知" :value="0"></el-option>
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" prop="m_idcard">
              <el-input
                v-model="ruleForm.m_idcard"
                placeholder="请输入身份证号"
                clearable
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="联系方式" prop="m_mobile">
              <el-input
                v-model="ruleForm.m_mobile"
                placeholder="请输入联系方式"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="会员来源" prop="m_platform">
              <el-select
                v-model="ruleForm.m_platform"
                placeholder="请选择会员来源"
                clearable
                :disabled="true"
              >
                <el-option
                  v-for="el in PlatformOptions"
                  :key="el.id"
                  :label="el.name"
                  :value="el.id"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="所属公司" prop="m_company">
              <el-input
                v-model="ruleForm.m_company"
                clearable
                placeholder="请输入所属公司"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="职位名称" prop="m_position">
              <el-input
                v-model="ruleForm.m_position"
                placeholder="请输入职位名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="加入社群" prop="communityList">
              <el-select
                v-model="ruleForm.communityList"
                clearable
                multiple
                collapse-tags
                placeholder="请选择加入社群"
              >
                <el-option
                  :label="item.mc_name"
                  :value="item.mc_id"
                  v-for="item in communityOptions"
                  :key="item.mc_id"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { VipApi, BasicApi, OrderApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { checkStr } from "@/utils/common.js";

const communityOptions = ref([]);
const PlatformOptions = ref([]);
onMounted(() => {
  tableRef.value.tableLoad();
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      PlatformOptions.value = res.Data;
      communityOptions.value = res.Data;
      filters.value[0].options = res.Data;
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
});

const filters = ref([
  {
    filterType: "select",
    name: "pid",
    value: "",
    placeholder: "请选择项目",
    options: [],
    lab: "p_name",
    val: "p_id",
  },
]);
/** 表格对象 */
const tableRef = ref(null);

/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "rr_id",
    label: "规则ID",
    color: "--text-third-color",
  },
  {
    prop: "project",
    type: "obj",
    objKey: "p_name",
    label: "所属项目",
    color: "--text-third-color",
  },

  {
    prop: "rr_desc",
    label: "规则内容",
  },
  {
    prop: "rr_type_text",
    label: "规则类型",
    color: "--text-third-color",
  },
  {
    prop: "rr_status",
    label: "规则状态",
    type: "switch",
    token: "o_DrgKWw8jpbaoVc9CJSzF1dOTZnUP",
  },

  {
    prop: "creator",
    type: "obj",
    objKey: "u_name",
    label: "创建人",
    color: "--text-third-color",
  },
  {
    prop: "rr_ctime",
    label: "创建时间",
    minWidth: 100,
    color: "--text-third-color",
  },
  // {
  //   type: "operation",
  //   prop: "",
  //   label: "操作",
  //   minWidth: 100,
  //   bottons: [
  //     {
  //       name: "编辑",
  //       action: "edit",
  //       token: "o_DrgKWw8jpbaoVc9CJSzF1dOTZnUP",
  //     },
  //   ],
  // },
]);
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    rr_id: row.rr_id,
    rr_status: row.rr_status == 1 ? 2 : 1,
  };
  OrderApi.setRefundStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("操作成功！");
      // 重新获取标签列表数据
      tableRef.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
};
const dialogRef = ref(null);
const formRef = ref(null);
const ruleForm = ref({
  m_realname: "",
  m_sex: "",
  m_idcard: "",
  m_mobile: "",
  m_platform: "",
  m_company: "",
  m_position: "",
  communityList: [],
  community: "",
});
const rules = reactive({
  m_realname: [
    {
      required: true,
      message: "请输入会员姓名",
      trigger: "blur",
    },
  ],
  m_mobile: [
    {
      required: true,
      message: "请输入联系方式",
      trigger: "blur",
    },
  ],
  m_idcard: [
    {
      required: true,
      message: "请输入身份证号",
      trigger: "blur",
    },
  ],
  // m_company: [
  //   {
  //     required: true,
  //     message: "请输入所属公司",
  //     trigger: "blur",
  //   },
  // ],
  // m_position: [
  //   {
  //     required: true,
  //     message: "请输入公司职位",
  //     trigger: "blur",
  //   },
  // ],
});
const openDialog = (row) => {
  if (row.m_id) {
    //编辑
    const data = JSON.parse(JSON.stringify(row));
    data.communityList = [];
    if (data.community.length) {
      data.community.forEach((el) => {
        data.communityList.push(el.mc_id);
      });
    }
    ruleForm.value = data;
  } else {
    // 新增
    ruleForm.value = {
      m_realname: "",
      m_sex: "",
      m_idcard: "",
      m_mobile: "",
      m_platform: "",
      m_company: "",
      m_position: "",
      communityList: [],
      community: "",
    };
  }
  dialogRef.value.show();
};
/** 新增/编辑 确认提交 */
const handleSure = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      dialogRef.value.isLoading = true;
      const data = JSON.parse(JSON.stringify(ruleForm.value));
      if (!checkStr(data.m_realname, "chinese")) {
        ElMessage.warning("请输入正确的会员姓名！");
        dialogRef.value.isLoading = false;
        return false;
      }
      if (!checkStr(data.m_mobile, "phone")) {
        ElMessage.warning("联系方式格式错误！");
        dialogRef.value.isLoading = false;
        return false;
      }
      if (!checkStr(data.m_idcard, "card")) {
        ElMessage.warning("身份证格式错误！");
        dialogRef.value.isLoading = false;
        return false;
      }
      if (data.communityList.length) {
        data.community = data.communityList.join(",");
      } else {
        data.community = "";
      }

      let parmas = {
        m_realname: data.m_realname,
        m_sex: data.m_sex,
        m_mobile: data.m_mobile,
        m_idcard: data.m_idcard,
        m_position: data.m_position,
        m_company: data.m_company,
        community: data.community,
        m_platform: data.m_platform,
      };
      let url = "";
      if (data.m_id) {
        // 编辑
        url = "memberUpdate";
        parmas["m_id"] = data.m_id;
      } else {
        // 新增
        url = "memberAdd";
      }
      VipApi[url](parmas).then((res) => {
        dialogRef.value.isLoading = false;
        const text = data.m_id ? "修改" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`会员${text}成功！`);
          dialogRef.value.close();
          tableRef.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `会员${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};
</script>
<style lang="scss">
.premium-container {
  font-family: "Source Han Sans CN";
  .content {
    padding: 15px 20px 20px;
  }
  .vip-dialog {
    .el-dialog {
      min-width: 600px;
      .el-dialog__body {
        padding: 30px;
        .el-divider--horizontal {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
